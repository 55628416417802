<template>
<div class="dashboard">

    <Menu :title="`PSO Detail ${ storeName }`"></Menu>

    <div class="container">
		<template v-if="isFilterFull">
			<template v-if="isLoading">
				<skeleton />
			</template>

			<template v-else>
				<div class="dashboard__section">
					<Table class="dashboard__table" :columns="tableColumns" :data="tableData">
						<template #percent="{ item, value, col: { name } }">
							<template v-if="isActiveGoal(value, item, name) && name && !!value">
								<Tooltip class="w-full" trigger="clickToToggle">
									<template slot="reference">
										<div class="goal goal-doubled-table" :class="!!value ? `--${getGoalClass(value)}` : '--none'">{{
											value
										}}%
										</div>
									</template>
									<template>
										<div>Target: {{ value }} %</div>
						<!--                  <div>Target: {{ Math.round(target(item[name])) }} %</div>-->
						<!--                  <div v-if="name === 'availability'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
						<!--                    ({{ value }}%) in stock-->
						<!--                  </div>-->
						<!--                  <div v-if="name === 'reviews'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
						<!--                    ({{ value }}%) had above 21 reviews-->
						<!--                  </div>-->
						<!--                  <div v-if="name === 'ratings'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
						<!--                    ({{ value }}%) had rating above 4.0-->
						<!--                  </div>-->
						<!--                  <div v-if="name === 'image'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
						<!--                    ({{ value }}%) had correct image-->
						<!--                  </div>-->
									</template>
								</Tooltip>
							</template>

							<template v-else>
								<div class="goal goal-doubled-table --none">No data</div>
							</template>

						</template>
					</Table>
				</div>
			</template>
		
		</template>

		<div class="empty-filter" v-else>Пожалуйста, выберите значения фильтра</div>
	</div>
</div>
</template>

<script>
import Menu from '@/components/Menu/Menu.vue';
import Table from '@/components/Table/Table.vue';
import Tooltip from '@/components/Elements/Tooltip.vue'
import Skeleton from "@/components/Nestle/Skeleton/Common.vue";

export default {
	name: "PSODetailsDashboard",
	components: {
		Menu,
		Table,
		Tooltip,
		Skeleton
	},

	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
		isFilterFull: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			goalsBelowCheckbox: {
				high: true,
				medium: true,
				light: true,
			},
			goalsAboveCheckbox: {
				light: true,
				medium: true,
				high: true,
			},
			tableData: [
				{
					id: null,
					name: '',
					pso: {value: null, lastValue: null},
					search: {value: null, lastValue: null},
					content: {value: null, lastValue: null},
					rating: {value: null, lastValue: null},
					availability: {value: null, lastValue: null},
					filling: {value: null, lastValue: null}
				}
			],
			isLoading: true,
			storeName: '',
			isMounted: false,
		}
	},
	computed: {
		tableColumns() {
			return [
				{
					title: '',
					width: 100,
					value: ({ name }) => name,
					lastValue: ({ lastValue }) => lastValue,
					cellClass: 'flex',
					children: [
						{ title: '' },
						{ title: '' },
					]
				},
				{
					title: 'PSO',
					name: 'pso',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ pso }) => pso.now,
					lastValue: ({ pso }) => pso.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
				{
					title: 'Поиск',
					name: 'search',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ search }) => search.now,
					lastValue: ({ search }) => search.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
				{
					title: 'Контент',
					name: 'content',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ content }) => content.now,
					lastValue: ({ content }) => content.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
				{
					title: 'Рейтинг',
					name: 'rating',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ rating }) => rating.now,
					lastValue: ({ rating }) => rating.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
				{
					title: 'Сток',
					name: 'availability',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ availability }) => availability.now,
					lastValue: ({ availability }) => availability.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
				{
					title: 'Филлинг репорт',
					name: 'filling',
					width: 100,
					slot: 'percent',
					cellClass: 'flex',
					value: ({ filling }) => filling.now,
					lastValue: ({ filling }) => filling.last,
					children: [
						{ title: 'Текущий период', classTitle: 'text-center child' },
						{ title: 'Прошлый период', classTitle: 'text-center' },
					]
				},
			]
		}
	},
	methods: {
		isActiveGoal(val) {
			let params = this.getGoalClass(val).split('-')
			return params[0] === 'below' ? this.goalsBelowCheckbox[params[1]] : this.goalsAboveCheckbox[params[1]]
		},

		getGoalClass(val) {
			let className;

			if (val < 101 && val >= 80) {
				className = 'above-high'
			} else if (val >= 66 && val <= 79.99) {
				className = 'above-medium'
			} else if (val >= 50 && val <= 65.99) {
				className = 'above-light'
			} else if (val >= 33 && val <= 49.99) {
				className = 'below-light'
			} else if (val >= 16 && val <= 32.99) {
				className = 'below-medium'
			} else {
				className = 'below-high'
			}
			return className
		},

		setTableData(data) {
			if (!data.length) return;
			data.forEach((el, index) => {
				console.log(this.tableData[index])
				for (let key in el) {
					if (key in this.tableData[index]) {
						if (key !== 'name' && key !== 'id') {
							this.tableData[index][key].value = el[key].now;
							this.tableData[index][key].lastValue = el[key].last;
						} else {
							this.tableData[index][key] = el[key];
						}
					}
				}
			});
		},
		async fetch() {
			if (!this.isFilterFull) return;

			this.isMounted = true;

			const businessUnitString = this.tableData.map(el => el.name).join(',')

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				businessUnit: businessUnitString,
			};

			try {
				this.isLoading = true

				const result = await this.$api.unilever.getPSODetailsData(params)

				this.tableData = result.items;

				this.store();
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}
		},

		store() {
			this.storeName = this.tableData?.[0].name || '';
		},
	},
	watch: {
		needUpdateResult: {
			async handler() {
				await this.fetch()
			}
		},
	}
}
</script>

<style scoped lang="sass">
@import "/src/scss/resources"
.dashboard
	&__table
		margin-top: 36px
		.goal
			height: 40px
			justify-content: center
			font-weight: 600
			display: flex
			align-items: center
			border-radius: 5px

			&.--none
				background-color: color(gray-200)
				color: color(gray-700)
				cursor: not-allowed

			&.--above-high
				background-color: color(goal-above-high)

			&.--above-medium
				background-color: color(goal-above-medium)

			&.--above-light
				background-color: color(goal-above-light)

			&.--below-light
				background-color: color(goal-below-light)

			&.--below-medium
				background-color: color(goal-below-medium)

			&.--below-high
				background-color: color(goal-below-high)

			&-doubled-table
				max-width: 65px
				display: flex
				align-items: center
				justify-content: center
				&.--none
					font-size: 12px
					line-height: 1.1
					min-width: 65px
					margin-right: 6px

	&__section
		padding: 20px
		background-color: color(white)
		border-radius: 4px

	.additional-link
		color: #ffffff
		margin-right: 15px

.empty-filter
	font-size: 16px
	display: flex
	justify-content: center
	margin-top: 30px

</style>
